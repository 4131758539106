@font-face {
  font-family: "VoltaBold";
  src: url("../../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}
* {
  word-spacing: 1.5px;
  font-family: "VoltaRoman";
}

.JourTitre {
  font-size: 22px;
  font-family: "VoltaBold";
  word-spacing: 1.5px;
  color: #0460a9;
  text-decoration: underline;
}

.SessionCampus {
  font-family: "VoltaRoman";
  position: relative;
  top: 20px;
}

.SessionCampus ul {
  margin-bottom: 5%;
}

.SessionCampus li {
  position: relative;
  top: 10px;

  margin-left: 40px;
}

.DateProgramme {
  font-family: "VoltaMedium";
  color: #0460a9;
}

.DateProgrammeHeure {
  font-size: 16px;
  font-family: "VoltaBold";
  word-spacing: 1.5px;
}
.DateProgrammeDate {
  font-family: "VoltaMedium";
}

.ProgContainer {
  overflow: scroll;
  height: 535px;
  overflow-x: hidden;
  z-index: -1;
}
.Moderateur {
  margin-left: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.partiBlock {
  margin-bottom: 10px;
}

.M1 {
}
@media screen and (max-width: 1680px) {
  .ProgContainer {
    overflow: scroll;
    height: 500px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1440px) {
  .SessionCampus li {
    font-size: 12px;
  }
  form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    font-size: 12px;
  }

  .ProgContainer {
    overflow: scroll;
    height: 450px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1370px) {
  .SessionCampus li {
    font-size: 11.5px;
  }
  form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    font-size: 11.5px;
  }

  .ProgContainer {
    overflow: scroll;
    height: 450px;
    overflow-x: hidden;
  }
}
