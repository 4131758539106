@font-face {
  font-family: "VoltaBold";
  src: url("../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}

.containerPgrogrammeCampus {
  margin-left: 18%;
  background-color: #ededed;
}
.UlClass {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
  display: flex;
  font-size: 16px;
}

.LIclasses {
  width: 33.33%;
  font-weight: bold;
  color: #adadad;
  padding-top: 10px;
}
.LIclasses a {
  padding: 10%;
  cursor: pointer;
}
.btnActive {
  background-color: #ededed;
  color: black !important;

  font-family: "VoltaBold";
}
.jours3 {
  height: 600px;
}

@media screen and (max-width: 1920px) {
  .containerPgrogrammeCampus {
  }
  .jours3 {
    height: 550px;
  }
  .UlClass {
    font-size: 14px;
  }
}

@media screen and (max-width: 1680px) {
  .containerPgrogrammeCampus {
  }
  .jours3 {
    height: 550px;
  }
  .UlClass {
    font-size: 14px;
  }
}

@media screen and (max-width: 1440px) {
  .UlClass {
    font-size: 12px;
  }

  .jours3 {
    height: 480px;
  }
}

@media screen and (max-width: 1280px) {
  .UlClass {
    font-size: 12px;
  }

  .jours3 {
    height: 440px;
  }
}
@media screen and (max-width: 900px) {
  .containerPgrogrammeCampus {
    width: 100%;
  }
}
