@font-face {
  font-family: "VoltaBold";
  src: url("../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}

.ShareFooter {
  width: 100%;
  /*position: absolute;*/
}

.ShareFooter {
  margin-left: 1.6%;

  font-size: 12px;
  padding-left: 10%;
  padding-right: 8%;
  padding-bottom: 10px;
}
.BlueColor {
  color: #5291dd;
}

@media screen and (max-width: 1920px) {
  .ShareFooter {
    position: relative;
    top: 23vh;
    font-size: 11px;
  }
}

@media screen and (max-width: 1680px) {
  .ShareFooter {
    font-size: 11px;
  }
}

@media screen and (max-width: 1440px) {
  .ShareFooter {
    font-size: 9px;
    position: relative;
    top: 14vh;
  }
  .FooterContactINS strong {
    font-size: 12px;
  }

  .FooterContactINS {
    font-size: 10px;
    padding-top: 0px;
  }

  .FooterInfoIns {
    font-size: 11px;
  }

  .ShareFooterIns {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1370px) {
  .ShareFooter {
    font-size: 8.5px;
    position: relative;
    top: 15vh;
  }
  .FooterContactINS strong {
    font-size: 11px;
  }

  .FooterContactINS {
    font-size: 9.5px;
    padding-top: 0px;
  }

  .FooterInfoIns {
    font-size: 10px;
  }

  .ShareFooterIns {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1280px) {
  .ShareFooter {
    font-size: 8px;
    position: relative;
    top: 9vh;
  }
  .FooterContactINS strong {
    font-size: 10px;
  }

  .FooterContactINS {
    font-size: 8.5px;
    padding-top: 0px;
  }

  .FooterInfoIns {
    font-size: 9px;
  }

  .ShareFooterIns {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1240px) {
  .ShareFooter {
    font-size: 7.5px;
    position: relative;
    top: 11vh;
  }
}

@media screen and (max-width: 1180px) {
  .ShareFooter {
    font-size: 6.5px;
    position: relative;
    top: 10vh;
  }
  .FooterContactINS strong {
    font-size: 10px;
  }

  .FooterContactINS {
    font-size: 8.5px;
    padding-top: 0px;
  }

  .FooterInfoIns {
    font-size: 9px;
  }

  .ShareFooterIns {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .ShareFooter {
    font-size: 6.5px;
    position: relative;
    top: 14vh;
  }
}

@media screen and (max-width: 1024px) {
  .ShareFooter {
    font-size: 5.5px;
    position: relative;
    top: 14vh;
  }
  .FooterContactINS strong {
    font-size: 10px;
  }

  .FooterContactINS {
    font-size: 8.5px;
    padding-top: 0px;
  }

  .FooterInfoIns {
    font-size: 9px;
  }

  .ShareFooterIns {
    padding-top: 0px;
  }
}

@media screen and (max-width: 900px) {
  .ShareFooter {
    width: 100%;
    position: relative;
    bottom: 0px;
    top: 1080px;
  }
}

@media screen and (max-width: 500px) {
  .ShareFooter {
    width: 100%;
    position: relative;
    bottom: 0px;
    top: 1080px;
  }
}

@media screen and (max-width: 380px) {
  .ShareFooter {
    width: 100%;
    position: relative;
    bottom: 0px;
    top: 1100px;
  }
}
