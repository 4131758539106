@font-face {
  font-family: "VoltaBold";
  src: url("../../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}
.CvContainer {
  font-family: "VoltaRoman";
  position: relative;
  top: 20px;
  padding-left: 8%;
  overflow: scroll;
  height: 440px;
}
