@font-face {
  font-family: "VoltaBold";
  src: url("../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}
.ContainerInscription {
  height: 610px;
  width: 100%;
  background-color: white;
}

.FormContainer {
  width: 100%;
  margin: auto;
}

.FormContainer2 {
  width: 80%;
  margin-left: 5%;
}

.titleInscription {
  margin-bottom: 20px;
}

.title0 {
  font-family: "VoltaMedium";
  color: #0460a9;
}

.title1 {
  font-family: "VoltaBold";
}

.title2 {
  font-family: "VoltaMedium";
  color: #0460a9;
}

.title3 {
  font-family: "VoltaRoman";
  color: #5291dd;
}

.textHerader {
  font-family: "VoltaRoman";
  font-size: 14px;
  color: #5291dd;
  margin-top: -20px;
}

.flex-container2 {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.flex-container2 .inside1 {
  width: 50%;
}
.flex-container2 .inside2 {
  width: 50%;

  margin-right: 15px;
  margin-bottom: 15px;
}

input[type="text"],
input[type="email"],
input[type="email"] {
  margin: 5px;
  width: 90%;
  padding: 12px 20px;
  outline: 0;
  border-width: 0 0 1px;
  border-color: rgb(97, 97, 97);

  background-color: white;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  background-color: white;
  width: 100%;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */

  background-color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */

  background-color: white;
}

.form-check-label {
  display: block;
}
.form-control {
  border-radius: 0px;
}

.bottomInscription {
  font-size: 13.5px;
  color: white;
  background-color: #5291dd;
  padding: 10px;
  font-family: "VoltaRoman";
}

.btnValider2 img {
  width: 300px;
  margin: auto;
  z-index: 99;
  cursor: pointer;
}
.btnValider2 {
  padding-top: 2%;
  display: flex;
  margin: auto;
}

.verticalFooterInscrits {
  position: relative;
  height: 610px;
  left: 0;
  top: 0;
  width: 80px;
  float: left;
}

.verticalInscritsImg {
  width: 35px;
}

.title1,
.title3,
.titleConfirme {
  font-size: 16px;
}

.title2 {
  font-size: 18px;
}
.title0 {
  font-size: 20px;
}

.titleConfirme {
  margin-right: 12px;
  font-weight: bold;
}

.FooterContainerIns {
  width: 100%;
  margin: auto;
  padding-top: 20px;
}

.FooterInfoIns {
  display: flex;
  color: #0460a9;
  font-family: "VoltaMedium";
  font-size: 12px;
  font-weight: 700;
}
.FooterInfoIns .bloc2 {
  margin-left: 20%;
}
.FooterContactINS p {
  font-size: 12px;
}

.popup {
  background-color: rgba(255, 255, 255, 0.5);
}
.BlockPopup {
  background-color: #5382c2;
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border: 3px solid #5382c2;
  text-align: center;
}
.textPopup {
  margin-bottom: 2%;
}

.loadingLogo {
  width: 50px;
}
.BlueCadre {
  width: 680px;
  /*height: 386px;*/
  height: 430px;
  background-color: #0460a9;
}

.PlayCadre {
  display: flex;
  justify-content: center;
}

.imgPlay {
  width: 95%;
  position: relative;
  top: 10px;
}
.titreTeaser {
  color: white;
  font-family: "VoltaBold";
  position: relative;

  display: flex;
  justify-content: center;
  font-weight: bold;
}
.titreTeaser p {
  margin-top: -10px;
  word-spacing: 2px;
}

@media screen and (max-width: 1680px) {
  .title1,
  .title3,
  .titleConfirme {
    font-size: 14px;
  }

  .title2 {
    font-size: 16px;
  }

  .title0 {
    font-size: 20px;
  }

  .bottomInscription {
    font-size: 12px;
  }

  .textHerader {
    font-size: 12px;
  }

  .ContainerInscription {
    height: 550px;
    width: 100%;
    background-color: white;
  }

  .BlueCadre {
    width: 540px;
    /*height: 386px;*/
    height: 390px;
    background-color: #0460a9;
  }

  .titreTeaser {
    margin-top: 12%;
  }
}

@media screen and (max-width: 1920px) {
  .title1,
  .title3,
  .titleConfirme {
    font-size: 14px;
  }

  .title2 {
    font-size: 16px;
  }
  .title0 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .bottomInscription {
    font-size: 12px;
  }

  .textHerader {
    font-size: 12px;
  }

  .ContainerInscription {
    height: 550px;
    width: 100%;
    background-color: white;
  }

  .BlueCadre {
    width: 540px;
    /*height: 386px;*/
    height: 390px;
    background-color: #0460a9;
  }

  .titreTeaser {
    margin-top: 12%;
  }
}

@media screen and (max-width: 1440px) {
  .titleInscription {
    margin-bottom: 10px;
  }
  .title1,
  .title3,
  .titleConfirme {
    font-size: 12px;
  }

  .title2 {
    font-size: 14px;
  }
  .title0 {
    font-size: 16px;
  }

  .bottomInscription {
    font-size: 10px;
  }

  .textHerader {
    font-size: 10px;
  }

  input[type="text"],
  input[type="email"],
  input[type="email"] {
    font-size: 12px;
  }

  input[type="radio"] {
    width: 11px;
    height: 11px;
    border-radius: 11px;
  }

  .BlueCadre {
    width: 440px;
    /*height: 386px;*/
    height: 320px;
    background-color: #0460a9;
    margin-bottom: 42px;
  }

  .titreTeaser {
    margin-top: 10%;
    font-size: 12px;
  }
}

@media screen and (max-width: 1370px) {
  .title1,
  .title3,
  .titleConfirme {
    font-size: 12px;
  }

  .title2 {
    font-size: 14px;
  }

  .title0 {
    font-size: 16px;
  }

  .bottomInscription {
    font-size: 10px;
  }

  .textHerader {
    font-size: 10px;
  }

  .BlueCadre {
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 1280px) {
  .title1,
  .title3,
  .titleConfirme {
    font-size: 11px;
  }

  .title2 {
    font-size: 13px;
  }

  .title0 {
    font-size: 14px;
  }

  .bottomInscription {
    font-size: 9px;
  }

  .textHerader {
    font-size: 9px;
  }
  .BlueCadre {
    margin-bottom: 20px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .loadingLogo {
    animation: logo-spin infinite 3s linear;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.linkPlay {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.linkPlay2 {
  color: white;
  font-family: "VoltaMedium";
  text-align: center;
  font-size: large;
  margin-top: 25%;
  font-weight: bold;
}

@media screen and (max-width: 1180px) {
  .btnValider2 img {
    width: 220px;
    margin: auto;
  }

  input[type="text"],
  input[type="email"],
  input[type="email"] {
    font-size: 10px;
  }

  .title1,
  .title3,
  .titleConfirme {
    font-size: 11px;
  }

  .title2 {
    font-size: 13px;
  }

  .title0 {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .ContainerInscription {
    height: 500px;
    width: 100%;
    background-color: white;
  }
  .BlueCadre {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
  }
  .FormContainer2 {
    width: 100%;
    margin-left: 1vh;
  }

  .ContainerV2 {
    width: 91%;
  }
  form {
    width: 100%;
  }
  .titleInscription2 {
    position: relative;
    margin-top: 26px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 500px) {
  .ContainerInscription {
    height: 500px;
    width: 100%;
    background-color: white;
  }
  .BlueCadre {
    width: 100%;
  }
  .FormContainer2 {
    width: 100%;
    margin-left: 1vh;
  }

  .ContainerV2 {
    width: 91%;
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
  opacity: 1;
}
