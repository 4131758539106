@font-face {
  font-family: "VoltaBold";
  src: url("../../../fonts/VoltaModernDisplay\ 75Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "VoltaRoman";
  src: url("../../../fonts/VoltaModernDisplay\ 55Roman.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "VoltaMedium";
  src: url("../../../fonts/VoltaModernDisplay\ 65Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "VoltaItalic";
  src: url("../../../fonts/VoltaModernDisplay\ 56Italic.otf");
  font-weight: 400;
  font-style: normal;
}

.QuizzContainer {
  font-family: "VoltaRoman";
  position: relative;
  top: 20px;
  padding-left: 8%;
  padding-right: 8%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 570px;
  z-index: 4;
}

.QuizzQuestionTitle {
  font-family: "VoltaBold";
}

.QuizzSectionTitle {
  font-family: "VoltaMedium";
  color: #0460a9;
  margin-top: 30px;
}

.QuizzQuestion {
  margin-top: 1vh;
  margin-bottom: 20px;
}

.QuizzAnswer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 4;
  margin: auto;
}

.QuizzAnswerTwin {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0.1;
  flex-shrink: 0;
  margin: 0.11%;
}

.QuizzQuestionsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 4;
  align-content: space-around;
}

.QuizzRadio input[type="radio"] {
  align-self: center;
  opacity: 0;
  position: fixed;
  width: 0;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  background-color: #6c757d;
  opacity: 1;
  height: 4vh;
  z-index: 6;
}

.validateButton {
  border-radius: 50px;
  border: 1px solid #0460a9;
  color: #0460a9;
  background-color: white;
  margin: auto;
}

hr.solid {
  border-top: 1px solid #0460a9;
}

@media screen and (max-width: 1920px) {
  .QuizzContainer {
    height: 500px;
    overflow-x: hidden;
  }
  .buttonDiv {
    position: relative;
    top: 12px;
  }
}

@media screen and (max-width: 1680px) {
  .QuizzContainer {
    height: 470px;
    overflow-x: hidden;
  }

  .buttonDiv {
    position: relative;
    top: 34px;
  }
}

@media screen and (max-width: 1440px) {
  .QuizzContainer {
    height: 450px;
    font-size: 12px;
    overflow-x: hidden;
  }

  .buttonDiv {
    position: relative;
    top: -8px;
  }
}

@media screen and (max-width: 1280px) {
  .QuizzContainer {
    height: 410px;
    font-size: 12px;
    overflow-x: hidden;
  }

  .buttonDiv {
    position: relative;
    top: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .buttonDiv {
    position: relative;
    top: 8px;
  }
}

.SmallPopup {
  background-color: #5382c2;
  color: white;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border: 3px solid #5382c2;
  text-align: center;
}
.textPopup {
  margin-bottom: 2%;
}
